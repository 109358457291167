import React, { Component, useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { Translate } from '../../components/translations';
import TriangleTwo from '../../img/triangle_two.png';
import TriangleThree from '../../img/triangle_three.png';
import { PageHeroProps } from '../../types';
import './styles.scss';

// import ltd from '../../img/pricing/LTD-agency-plan.png';
// import discount from '../../img/pricing/50- off-annual.png';
import { getCookiePolicy, getPromoHeader } from '../../helpers';

export class PageHero extends Component<PageHeroProps> {
  render() {
    const {
      title,  
      subtitle   ,
      subtitle1,
      children,
      countdown,
      pricing,
    } = this.props;

    // const cookiePadding = (getCookiePolicy() !== "registered") ? 'with-cookie' : 'no-cookie';

    return (
      <section
        id='seo_home_area'
        className={`seo_home_area ${this.props.cls ? this.props.cls : ''}`}
      >
        {/* <div className="home_bubble">
          <div className="bubble b_one" />
          <div className="bubble b_two" />
          <div className="bubble b_three" />
          <div className="bubble b_four" />
          <div className="bubble b_five" />
          <div className="bubble b_six" />
          <div className="triangle b_eight">
            <img src={TriangleTwo} alt="" />
          </div>
          <div className="triangle b_nine">
            <img src={TriangleThree} alt="" />
          </div>
        </div> */}
        <div className="container">
          <div className="breadcrumb_content text-center">
            {title && (
              <h1 className="page-hero-title">
                {typeof title === 'string' ? <Translate name={title} /> : title}
              </h1>
            )}
            {subtitle && (
              <>

                <p className="f_300 page-hero-subTitle">
                  {typeof subtitle === 'string' ? (
                    <Translate name={subtitle} />
                  ) : (
                    subtitle
                  )}
                </p>
                <div className='d-flex justify-content-center'>{countdown}</div>
                <p className="f_300 page-hero-subTitle mb-0" style={{fontSize: '16px'}}>
                  {typeof subtitle1 === 'string' ? (
                    <Translate name={subtitle1} />
                  ) : (
                    subtitle1
                  )}
                </p>
              </>
            )}
            
            {/* {pricing && 
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <img src={discount} alt='50 off annual' className='pricing-banners' onClick={() => navigate('/pricing')} />
                </div>
                <div className='col-12 col-md-6'>
                  <img src={ltd} alt='LTD agency plan' className='pricing-banners' onClick={() => navigate('/pricing/agency')} />
                </div>
              </div>
            } */}
            {children}
          </div>
        </div>
      </section>
    );
  }
}
