import React from 'react';
import {Link} from 'gatsby';
import {faWordpress} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import slack from '../../img/integrations/slack.svg';
import trello from '../../img/integrations/trello.svg';
import telegram from '../../img/integrations/telegram.svg';
import google from '../../img/integrations/google.svg';
import chrome from '../../img/integrations/chrome.svg';
import googleSheets from '../../img/integrations/google-sheets.svg';
import zapier from '../../img/zapier.png';
import pabbly from '../../img/integrations/pabbly.png';
import webhook from '../../img/integrations/webhooks.svg';
import discord from '../../img/integrations/discord.svg';
import './styles.scss';

const HomePageIntegrations = () => {
  const points = [
    {
      name: 'Telegram',
      logo: telegram,
      linkTo: '/telegrambot',
    },
    {
      name: 'Chrome',
      logo: chrome,
    },
    {
      name: 'Slack',
      logo: slack,
      linkTo: '/slackbot',
    },
    {
      name: 'WP',
      logo: faWordpress,
      linkTo: '/broken-links-repair',
    },
    {
      name: 'Gmail',
      logo: google,
    },
    {
      name: 'Trello',
      logo: trello,
      linkTo: '/trellobot',
    },
    {
      name: 'Google Sheets',
      logo: googleSheets,
    },
    {
      name: 'Zapier',
      logo: zapier,
      linkTo: '/zapier',
    },
    {
      name: 'Pabbly Connect',
      logo: pabbly,
      linkTo: '/pabbly-connect',
    },
    {
      name: 'Webhook',
      logo: webhook,
      linkTo: '/webhook',
    },
    {
      name: 'Discord',
      logo: discord,
      linkTo: '/discord',
    },
  ];

  return (
    <div className="text-center m-auto w-100" style={{maxWidth: '1200px'}}>
      <h2 className="f_size_27 f_700 t_color3 l_height40 mt_20 mb_5 text-center">Software Integrations</h2>
      <div className="integration-mobile row m-0">
        <p className="integration-text">
          Get alerts via email, Telegram, Discord, Slack Trello or integrate with 1000 apps via our Zapier,
          Integrately, SyncSpider or Pabbly Connect integrations
        </p>
        <div className="d-flex flex-wrap justify-content-center">
          {points.map(item => {
            return (
              <div key={item.name} className="integration-item">
                <div className="promo_item">
                  {item.linkTo ? (
                    <Link to={item.linkTo || ''}>
                      {item.name === 'WP' ? (
                        <FontAwesomeIcon size="3x" color="#26749c" icon={item.logo} />
                      ) : (
                        <img src={item.logo} alt={item.name} width={44} height={44} />
                      )}

                      <p>{item.name}</p>
                    </Link>
                  ) : (
                    <>
                      <img src={item.logo} alt={item.name} width={44} height={44} />
                      <p>{item.name}</p>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HomePageIntegrations;
