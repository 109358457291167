import React from 'react';
import {navigate} from '@reach/router';
import classNames from 'classnames';

import Layout from '../../components/layout/layout';
import {PageHero} from '../../components/page-hero';
import Button from '../../components/button';
import Intergrations from '../../components/homePageIntegration';

import agency from '../../img/soulitions/agency-1.png';
import agency2 from '../../img/soulitions/agency-2.png';
import hexagram from '../../img/services/ecommerce.png';
import dash from '../../img/Dash-Screen-for-Website.png';
import uptime from '../../img/services/uptime.png';
import standardMonitor from '../../img/soulitions/Standard-monitor.png';
import advancedImg from '../../img/soulitions/advanced.png';
import advancedMonitorImg from '../../img/soulitions/advanced-uptime-monitor.png';
import userExperiance from '../../img/services/User-expericence.png';
import mobile from '../../img/services/mobile.png';
import broken from '../../img/services/broken.png';
import seo from '../../img/services/seo.png';
import security from '../../img/services/security.png';

const services = [
  {
    title: null,
    text: (
      <>
        <p>
          Every day your clients face an increasing range of threats. Server problems, slow landing pages, broken links,
          frustrating mobile experiences, embarrassing spelling mistakes, changing SEO rules, 3rd party services
          breaking, or security issues that put their business at risk.
        </p>
        <p>To make matters worse, these issues can linger unnoticed impacting the ROI of your marketing campaigns.</p>
        <p>
          Hexometer helps agencies manage more clients and provide unrivaled service by continuously monitoring their
          clients websites so they can catch problems before they affect their customers.
        </p>
      </>
    ),
    image: agency,
    alt: 'agency',
  },
  {
    title: 'Comprehensive monitoring for the most demanding clients',
    text: (
      <>
        <p>
          Go beyond traditional uptime monitoring. Hexometer provides 24/7 peace of mind by continuously monitoring your
          clients and their key services for issues and improvement opportunities.
        </p>
        <p>
          Hexometer is like having your very own QA team, continuously auditing client websites across six core areas to
          catch downtime, slow pages, spelling or grammar mistakes, visual bugs across different device types, broken
          links, server or JS errors, SEO problems and security issues.
        </p>
      </>
    ),
    image: hexagram,
    alt: 'Designed for eCommerce',
  },
  {
    title: 'Built for agency teams & designed to make your agency shine',
    text: (
      <>
        <p>Hexometer features a multi-client agency dashboard to monitor all your clients in one workspace.</p>
        <p>Share access with your team assigning issues and tasks to team members</p>
        <p>Track issues, progress and fixes via our progress journal.</p>
        <p>
          Hexometer works behind the scenes and enables your agency to create branded reports to make your team shine.
        </p>
        <p>
          Get real-time notifications when issues are detected via email, Slack, Telegram, Discord & Trello.
          Hexometer can also connect to thousands of apps via Zapier, Integrately, SyncSpider, Pabbly Connect or custom
          webhooks.
        </p>
      </>
    ),
    image: agency2,
    alt: 'agency 2',
  },
  {
    title: null,
    text: (
      <div className="flex-align-center">
        <h5>Get started in minutes, not weeks</h5>
        <p>
          Hexometer works in the cloud and requires zero participation from your clients technical teams to get started.
          Sign up, add your client and Hexometer starts monitoring 24/7.
        </p>
        <Button primary className="trial_btn" onClick={() => navigate('/pricing/')}>
          Click here to get started in minutes
        </Button>
      </div>
    ),
    image: dash,
    alt: 'AI sidekick to protect and grow eCommerce business',
  },
  {
    title: 'Synthetic monitoring to bulletproof your business',
    text: (
      <>
        <p>
          <b>Standard monitors - uptime monitoring for any URL</b>
        </p>
        <p>
          Every minute counts when ad campaigns are running. With Hexometer you can deploy standard availability
          monitors on all your key landing pages to catch downtime and track HTTP response times down to every 60
          seconds.
        </p>
        <p>
          Standard monitors are ideal to keep a close eye on important landing pages, email servers, CDN, DNS, SFTP,
          RADIUS, POP3, IMAP and 3rd party services for downtime.
        </p>
        <div style={{margin: 'auto', textAlign: 'center'}}>
          <img src={standardMonitor} alt={'standard monitor'} className="service-img" />
        </div>
      </>
    ),
    image: uptime,
    alt: 'Uptime and Error monitoring',
  },
  {
    title: null,
    text: (
      <div className={'mg-minus'}>
        <h5>Advanced uptime monitors with keyword support to catch page level errors</h5>
        <p>
          Traditional uptime monitoring relies on HTTP response codes to detect downtime. With keyword based uptime
          monitoring you can check for the presence or absence of keywords on page to validate your uptime checks. This
          is ideal to catch errors or caching issues that would otherwise not be detected.
        </p>
        <div style={{margin: 'auto', textAlign: 'center'}}>
          <img src={advancedImg} alt={'advanced'} className="service-img" />
        </div>
      </div>
    ),
    image: advancedMonitorImg,
    alt: 'advanced monitoring',
  },
  {
    title: (
      <span>
        User experience monitoring to catch embarrassing issues before <br /> they affect your client
      </span>
    ),
    text: (
      <>
        <p>
          Deliver a first class experience by continuously proofreading client websites for spelling or grammar mistakes
          catching typos or human errors after pages are published
        </p>
        <p>
          Hexometer continuously performs multi-device UX testing at scale to ensure your clients pages display as
          intended.
        </p>
        <p>Troubleshoot UX issues by performing on-demand user experience and monitor your progress through time.</p>
      </>
    ),
    image: userExperiance,
    alt: 'user experience monitoring',
  },
  {
    title: (
      <span>
        Performance monitoring to spot slow loading pages before <br /> they affect your conversion rate
      </span>
    ),
    text: (
      <>
        <p>Hexometer continuously monitors your clients for performance issues across desktop and mobile devices.</p>
        <div>
          <small>- Check how pages display on up to 7 different device types</small>
        </div>
        <div>
          <small>- Analyze website performance on desktop and mobile devices</small>
        </div>
        <div>
          <small>- Monitor page load speed</small>
        </div>
        <div>
          <small>- Get actionable insights to fix performance issues</small>
        </div>
        <br />
        <p>
          Troubleshoot performance issues by performing on-demand performance audits featuring Google Web Vitals on any
          page and monitor progress through time.
        </p>
      </>
    ),
    image: mobile,
    alt: 'Performance monitoring & optimisations',
  },
  {
    title: 'Health monitoring to detect deal breaking errors, broken links and missing images',
    text: (
      <>
        <p>
          Website updates gone wrong, server issues or 3rd party services experiencing problems are like kryptonite for
          your clients. Causing pages, links, images or key functionality such as shopping carts not to work.
        </p>
        <p>
          Hexometer continuously monitors websites for broken links, server errors, JS errors, missing images or
          problematic scripts that can sabotage a business.
        </p>
        <p>
          Troubleshoot issues by performing on-demand page audits to uncover JS errors, W3C issues, server errors.
          Monitor your progress through time and get detailed debugging information to pinpoint or fix problems.
        </p>
      </>
    ),
    image: broken,
    alt: 'Broken link, server error and JS bug monitoring',
  },
  {
    title: 'SEO monitoring to improve and protect search engine traffic',
    text: (
      <>
        <p>
          Hexometer continuously monitors websites for SEO issues that can impact search engine performance highlighting
          problems and opportunities.
        </p>
        <p>Hexometer keeps an eye on a wide range of factors including:</p>
        <div>
          <small>- Title and description meta tags</small>
        </div>
        <div>
          <small>- Duplicate content issues</small>
        </div>
        <div>
          <small>- Broken links</small>
        </div>
        <div>
          <small>- Robots.txt</small>
        </div>
        <div>
          <small>- Indexing issues</small>
        </div>
        <div>
          <small>- Header Structure</small>
        </div>
        <div>
          <small>- Sitemap issues</small>
        </div>
        <div>
          <small>- Open Graph optimizations</small>
        </div>
        <br />
        <p>Hexometer integrates with Google Search Console to provide page performance metrics and ranking metrics.</p>
        <p>Troubleshoot SEO issues by performing on-demand performance audits and monitor progress through time.</p>
      </>
    ),
    image: seo,
    alt: 'SEO Optimisation',
  },
  {
    title: 'Security Monitoring to support client websites against cyber threats',
    text: (
      <>
        <p>
          Did you know that over thirty thousand websites are infected by some type of malware daily? Security issues
          can cause downtime, loss of reputation, blacklisted search rankings as well as a steep cost to fix and restore
          your website.
        </p>
        <p>
          Hexometer continually checks if your client is blacklisted by the leading security authorities and keeps an
          eye on security headers, SSL best practices, blacklisted IP’s as well as their homepage for malicious URLs.
        </p>
        <p>Troubleshoot security issues by performing on-demand audits and monitor your progress through time.</p>
      </>
    ),
    image: security,
    alt: 'Security monitoring',
  },
];

const Agencies: React.FC = (): JSX.Element => {
  return (
    <Layout>
      <PageHero
        title="The secret sauce that enables agencies to deliver world-class service"
        subtitle="Hexometer is your AI sidekick that works behind the scenes, monitoring your website and marketing 24/7"
      />
      <div className="ecommerce-and-retail">
        {services.map((item, index) => (
          <section
            key={item.image}
            className={classNames('services', {grey: index % 2 !== 0}, {'light-grey': index % 2 === 0})}
          >
            <div className="container">
              {item.title && (
                <h3 className="f_size_27 f_700 t_color3 l_height40 mt_20 mb_50 text-center">{item.title}</h3>
              )}
              <div
                className={classNames('row_services', {
                  'flow-reverse': index % 2 !== 0,
                })}
              >
                <div className="col-12 col-lg-6 p-0">{item.text}</div>
                <div className="col-12 col-lg-6 p-0 text-center">
                  <img src={item.image} alt={item.alt} className="service-img" />
                </div>
              </div>
            </div>
          </section>
        ))}
        <br />
        <br />
        <br />
        <Intergrations />
      </div>
    </Layout>
  );
};

export default Agencies;
